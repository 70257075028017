<template>
  <div class="project">
    <my-banner :banner="banner" />
    <div class="content">
      <div class="list">
        <div v-for="item of data.rows" :key="item.noticeId" class="item">
          <div class="img">
            <img :src="item.noticePicture" :key="item.noticePicture" alt="" />
          </div>
          <p class="title" @click="goToDetail(item.noticeId)">
            {{ item.noticeTitle }}
          </p>
          <p style="margin: 1px 0">{{ item.noticeTitle }}</p>
          <hr />
          <div class="address">
            <div>
              <img :src="require('@/assets/img/project/address.png')" />
            </div>
            <p class="noticeSubtitle">{{ item.noticeSubtitle }}</p>
          </div>
        </div>
      </div>
      <div class="pager">
        <div class="current">当前{{ pageNum }}/{{ total }}页</div>
        <a-pagination
          v-model="pageNum"
          :total="total * 10"
          @change="pageChange"
        />
        <div class="end" @click="endPage">尾页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, queryPhotoManageList } from "@/api/api.js";
export default {
  name: "project",
  data() {
    return {
      banner: "",
      pageNum: 1,
      data: "",
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "服务项目",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
  methods: {
    pageChange(page) {
      this.pageNum = page;
    },
    //尾页
    endPage() {
      if (this.data !== "") {
        this.pageNum = Math.ceil(this.data.total / 6);
      }
    },
    //跳转详情页面
    goToDetail(noticeId) {
      this.$router.push({
        path: "/projectDetail",
        query: {
          noticeId,
        },
      });
    },
  },
  watch: {
    pageNum: {
      handler(val) {
        getData({
          noticeType: 5,
          pageNum: val,
          pageSize: 6,
        }).then((res) => {
          this.data = res.data;
        });
      },
      immediate: true,
    },
  },
  computed: {
    total() {
      return this.data !== "" ? Math.ceil(this.data.total / 6) : 1;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and(min-width: 1200px) {
  .project {
    .content,
    .pager {
      width: 68%;
    }
  }
}
@media screen and(max-width: 1200px) {
  .project {
    .content,
    .pager {
      width: 90%;
    }
  }
}
@media screen and(max-width: 830px) {
  .project {
    .content {
      width: 95%;
    }
    .list {
      grid-template-columns: repeat(2, 50%) !important;
      grid-template-rows: repeat(3, 33.3%) !important;
      .img {
        max-height: 150px !important;
      }
    }
  }
}
.project {
  width: 100%;
  > .content {
    padding: 50px 0;
    margin: auto;
    > .list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 33.3%);
      grid-template-rows: repeat(2, 50%);
      > .item {
        cursor: pointer;
        padding: 10px;
        > .img {
          margin-bottom: 15px;
          overflow: hidden;
          max-height: 180px;
          > img {
            transform: scale(1);
            transition: 0.5s;
            width: 100%;
            object-fit: cover;
            height: auto;
          }
          > img:hover {
            transform: scale(1.2);
            transition: 0.5s;
          }
        }
        > .title {
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          color: #333333;
        }
        > .title:hover {
          color: #d20505;
        }
        > :nth-child(3) {
          .title();
          color: #999999;
        }
        > .address {
          display: flex;
          > div {
            width: 13px;
            margin-right: 5px;
            > img {
              width: 100%;
            }
          }
          .noticeSubtitle {
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
            text-overflow: ellipsis;
          }
        }
      }
    }
    > .pager {
      margin-top: 30px;
      display: flex;
      justify-content: right;
      width: 100%;
      > * {
        margin-left: 10px;
      }
      > .current {
        height: 32px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        line-height: 32px;
        padding: 0 10px;
      }
      > .end {
        height: 32px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        line-height: 32px;
        padding: 0 10px;
      }
      .current:hover,
      .end:hover {
        color: #1890ff;
        border: 1px solid #1890ff;
      }
    }
  }
}
</style>

<style lang="less">
.text {
  * {
    color: #999999 !important;
    font-size: 12px;
  }
}
</style>